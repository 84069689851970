function ProgressBar({ progress = 0, rounded = false }) {
    return (
        <div className="w-full">
            <span id="ProgressLabel" className="sr-only">Loading</span>
            <span
                role="progressbar"
                aria-labelledby="ProgressLabel"
                aria-valuenow="75"
                className={`block ${rounded ? 'rounded-full': ''} bg-gray-200`}
            >
                <span
                    className={`block h-3 ${rounded ? 'rounded-full': ''} bg-indigo-600`}
                    style={{ width: `${progress}%` }}
                ></span>
            </span>
        </div>
    );
}
export default ProgressBar