import React from "react";

function TypingMonkey() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="360"
            height="316"
            fill="none"
            viewBox="0 0 360 316"
        >
            <g clipPath="url(#clip0_6_2803)">
                <mask
                    id="mask0_6_2803"
                    style={{ maskType: "luminance" }}
                    width="517"
                    height="345"
                    x="-79"
                    y="0"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M438 0H-79v345h517V0z"></path>
                </mask>
                <g mask="url(#mask0_6_2803)">
                    <path
                        fill="#FEF3F3"
                        d="M255.589 182.212l3.468 69.572a1.65 1.65 0 00.763 1.314c1.096.7 2.533-.081 2.543-1.382l.593-69.065a1.657 1.657 0 00-1.45-1.658l-4.06-.507a1.655 1.655 0 00-1.857 1.726z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M262.338 180.997l-1.477 71.437c0 1.423 3.09 1.097 4.24.26l.231-.168c.424-.31.687-.795.714-1.32l3.512-69.237a1.742 1.742 0 00-1.339-1.783l-3.742-.883a1.74 1.74 0 00-2.139 1.694z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M300.576 166.302h.003v-3.054l-2.543-.966v1.02l-107.72-62.581a14.57 14.57 0 00-14.14-.279l-65.474 34.672 113.389 65.877a14.569 14.569 0 0014.141.279l60.791-32.192c1.098-.582 1.616-1.694 1.553-2.776z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M224.084 197.58l-113.389-65.877 65.473-34.672a14.572 14.572 0 0114.14.277l108.808 63.216c1.995 1.16 1.938 4.062-.1 5.142l-60.792 32.192a14.57 14.57 0 01-14.14-.278z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M230.465 154.155l-.005 1.459-19.429 11.303.005-1.46 19.429-11.302zM211.036 165.456l-.006 1.461-43.003-24.853.005-1.46 43.004 24.852z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M230.465 154.155l-19.43 11.302-43.003-24.852 19.429-11.302 43.004 24.852z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.088 132.147l-.002.857-2.689 1.564.003-.856 2.688-1.565zM187.4 133.711l-.003.856-2.652-1.532.002-.857 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.088 132.147l-2.688 1.564-2.653-1.533 2.689-1.562 2.652 1.531zM193.49 134.113l-.003.857-2.688 1.564.003-.857 2.688-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.803 135.677l-.003.857-2.653-1.533.003-.856 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M193.49 134.113l-2.688 1.564-2.653-1.533 2.689-1.563 2.652 1.532zM196.886 136.076l-.003.857-2.688 1.563.002-.856 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M194.198 137.64l-.003.855-2.652-1.532.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M196.886 136.076l-2.689 1.564-2.652-1.533 2.688-1.564 2.653 1.533zM200.281 138.038l-.002.857-2.689 1.563.003-.855 2.688-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M197.594 139.602l-.003.856-2.653-1.533.003-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.281 138.038l-2.688 1.564-2.653-1.533 2.689-1.564 2.652 1.533zM203.683 140.004l-.002.857-2.689 1.563.003-.855 2.688-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.996 141.568l-.003.856-2.653-1.533.002-.856 2.654 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M203.683 140.004l-2.688 1.565-2.653-1.534 2.689-1.563 2.652 1.532zM207.08 141.966l-.003.856-2.689 1.565.002-.857 2.69-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M204.391 143.53l-.003.857-2.652-1.533.003-.856 2.652 1.532zM207.08 141.967l-2.689 1.563-2.652-1.532 2.687-1.564 2.654 1.533zM210.475 143.928l-.003.856-2.689 1.565.003-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M207.787 145.493l-.003.855-2.652-1.532.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M210.475 143.929l-2.689 1.564-2.652-1.533 2.688-1.563 2.653 1.532zM213.877 145.895l-.003.856-2.688 1.564.003-.855 2.688-1.565zM211.189 147.459l-.003.856-2.659-1.537.002-.856 2.66 1.537z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M213.876 145.895l-2.687 1.564-2.659-1.537 2.688-1.564 2.658 1.537zM217.272 147.857l-.003.856-2.688 1.565.003-.856 2.688-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M214.585 149.422l-.003.855-2.653-1.532.002-.857 2.654 1.534z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M217.272 147.857l-2.688 1.565-2.653-1.534 2.689-1.563 2.652 1.532zM220.668 149.819l-.003.856-2.688 1.565.002-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M217.98 151.383l-.003.856-2.652-1.533.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M220.669 149.82l-2.689 1.564-2.652-1.533 2.687-1.564 2.654 1.533zM227.466 153.748l-.003.857-2.688 1.563.002-.855 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M224.778 155.311l-.003.857-6.055-3.499.002-.857 6.056 3.499z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M227.466 153.748l-2.689 1.565-6.055-3.501 2.689-1.562 6.055 3.498zM190.064 136.107l-.003.857-2.688 1.563.003-.856 2.688-1.564zM187.376 137.67l-.003.856-6.055-3.499.003-.856 6.055 3.499z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.064 136.107l-2.688 1.564-6.055-3.5 2.688-1.563 6.055 3.499zM193.459 138.069l-.003.856-2.688 1.563.002-.855 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.772 139.633l-.003.855-2.653-1.532.003-.855 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M193.459 138.069l-2.689 1.564-2.652-1.532 2.688-1.564 2.653 1.532zM196.855 140.031l-.003.856-2.688 1.563.002-.855 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M194.167 141.595l-.003.855-2.652-1.531.002-.856 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M196.855 140.031l-2.689 1.564-2.652-1.532 2.688-1.563 2.653 1.531zM200.257 141.997l-.002.856-2.689 1.565.003-.857 2.688-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M197.569 143.561l-.004.857-2.651-1.533.002-.856 2.653 1.532zM200.257 141.998l-2.688 1.563-2.653-1.532 2.689-1.564 2.652 1.533zM203.652 143.96l-.002.855-2.689 1.565.003-.856 2.688-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.965 145.524l-.004.855-2.652-1.532.002-.856 2.654 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M203.652 143.96l-2.688 1.564-2.653-1.533 2.688-1.563 2.653 1.532zM207.048 145.922l-.003.855-2.688 1.565.002-.855 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M204.36 147.486l-.003.856-2.653-1.533.003-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M207.048 145.922l-2.689 1.564-2.652-1.533 2.688-1.564 2.653 1.533zM210.45 147.888l-.003.856-2.688 1.565.002-.856 2.689-1.565zM207.761 149.453l-.002.855-2.658-1.536.002-.857 2.658 1.538z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M210.45 147.888l-2.688 1.565-2.659-1.538 2.689-1.563 2.658 1.536zM213.846 149.85l-.003.856-2.688 1.565.003-.856 2.688-1.565zM211.158 151.414l-.004.856-2.652-1.533.003-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M213.846 149.851l-2.688 1.564-2.653-1.533 2.688-1.564 2.653 1.533zM217.241 151.812l-.003.857-2.688 1.564.002-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M214.554 153.377l-.003.855-2.653-1.532.003-.857 2.653 1.534z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M217.241 151.813l-2.689 1.564-2.652-1.533 2.689-1.563 2.652 1.532zM220.644 153.779l-.003.856-2.689 1.564.002-.855 2.69-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M217.955 155.342l-.002.857-2.659-1.537.003-.856 2.658 1.536z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M220.644 153.779l-2.689 1.564-2.659-1.536 2.688-1.565 2.66 1.537zM224.039 155.742l-.002.856-2.689 1.563.002-.856 2.689-1.563z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M221.351 157.305l-.003.855-2.652-1.531.002-.857 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M224.039 155.742l-2.689 1.563-2.652-1.533 2.688-1.563 2.653 1.533zM186.637 138.1l-.003.856-2.688 1.563.002-.855 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M183.949 139.664l-.003.856-6.055-3.499.003-.856 6.055 3.499zM186.637 138.1l-2.688 1.564-6.054-3.499 2.687-1.564 6.055 3.499zM190.032 140.062l-.002.856-2.689 1.564.003-.856 2.688-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M187.345 141.626l-.004.856-2.652-1.532.003-.856 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.032 140.062l-2.688 1.564-2.653-1.532 2.688-1.563 2.653 1.531zM193.428 142.024l-.003.857-2.688 1.564.003-.856 2.688-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M190.74 143.589l-.002.856-2.653-1.533.003-.856 2.652 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M193.428 142.025l-2.688 1.564-2.653-1.533 2.689-1.564 2.652 1.533zM196.83 143.991l-.003.855-2.688 1.565.003-.856 2.688-1.564zM194.142 145.555l-.003.855-2.652-1.532.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M196.83 143.991l-2.688 1.564-2.653-1.533 2.688-1.563 2.653 1.532zM200.226 145.953l-.003.856-2.689 1.564.003-.855 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M197.538 147.517l-.003.856-2.652-1.532.002-.857 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.226 145.953l-2.689 1.564-2.652-1.533 2.688-1.564 2.653 1.533zM203.621 147.915l-.002.856-2.689 1.565.003-.856 2.688-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.934 149.48l-.003.855-2.653-1.532.002-.857 2.654 1.534z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M203.621 147.915l-2.688 1.565-2.653-1.534 2.688-1.563 2.653 1.532zM207.024 149.881l-.003.856-2.688 1.565.003-.856 2.688-1.565zM204.336 151.445l-.003.856-2.66-1.536.003-.857 2.66 1.537z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M207.023 149.882l-2.687 1.564-2.659-1.537 2.687-1.564 2.659 1.537zM210.419 151.843l-.003.857-2.688 1.564.002-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M207.731 153.408l-.003.855-2.652-1.532.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M210.419 151.843l-2.689 1.565-2.652-1.533 2.688-1.564 2.653 1.532zM213.814 153.807l-.002.855-2.689 1.564.003-.856 2.688-1.563z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M211.127 155.37l-.003.856-2.653-1.532.003-.857 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M213.814 153.807l-2.688 1.563-2.653-1.533 2.689-1.564 2.652 1.534zM220.612 157.735l-.003.856-2.688 1.564.003-.856 2.688-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M217.924 159.298l-.002.856-6.054-3.498.002-.856 6.054 3.498z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M220.612 157.735l-2.688 1.564-6.054-3.499 2.688-1.564 6.054 3.499zM186.6 142.059l-.003.856-2.689 1.565.003-.857 2.689-1.564zM183.911 143.623l-.003.856-9.449-5.461.002-.857 9.45 5.462z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M186.599 142.059l-2.688 1.564-9.45-5.461 2.688-1.563 9.45 5.46zM189.995 144.022l-.004.855-2.688 1.565.003-.856 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M187.307 145.585l-.004.856-2.651-1.532.002-.856 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M189.995 144.022l-2.689 1.563-2.652-1.532 2.687-1.563 2.654 1.532zM193.397 145.988l-.003.856-2.688 1.565.003-.856 2.688-1.565zM190.709 147.552l-.003.856-2.652-1.533.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M193.397 145.988l-2.688 1.565-2.653-1.533 2.688-1.565 2.653 1.533zM196.792 147.95l-.003.855-2.688 1.565.003-.855 2.688-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M194.104 149.514l-.002.856-2.653-1.533.003-.856 2.652 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M196.792 147.95l-2.688 1.565-2.653-1.533 2.689-1.564 2.652 1.532zM200.188 149.912l-.003.857-2.688 1.564.002-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M197.5 151.476l-.003.856-2.652-1.533.002-.856 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.188 149.913l-2.689 1.564-2.652-1.533 2.688-1.564 2.653 1.533zM203.59 151.879l-.002.855-2.689 1.565.002-.855 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.902 153.443l-.002.855-2.659-1.535.002-.857 2.659 1.537z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M203.59 151.879l-2.689 1.564-2.659-1.537 2.689-1.564 2.659 1.537zM206.986 153.84l-.004.856-2.687 1.565.002-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M204.298 155.405l-.003.856-2.652-1.532.002-.857 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M206.986 153.841l-2.688 1.564-2.653-1.533 2.688-1.563 2.653 1.532zM210.382 155.804l-.003.856-2.689 1.563.002-.855 2.69-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M207.693 157.367l-.003.856-2.652-1.532.003-.856 2.652 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M210.381 155.804l-2.689 1.564-2.651-1.533 2.688-1.564 2.652 1.533zM217.179 159.732l-.003.857-2.688 1.563.002-.856 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M214.491 161.296l-.003.855-6.054-3.498.002-.856 6.055 3.499z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M217.179 159.732l-2.689 1.564-6.054-3.499 2.688-1.564 6.055 3.499zM176.375 140.124l-.003.856-2.688 1.564.002-.856 2.689-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M173.687 141.688l-.003.856-2.652-1.532.002-.856 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M176.375 140.124l-2.689 1.564-2.652-1.532 2.688-1.563 2.653 1.531zM179.777 142.09l-.002.857-2.689 1.564.003-.856 2.688-1.565zM177.089 143.654l-.003.856-2.652-1.532.002-.857 2.653 1.533z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M179.777 142.09l-2.688 1.564-2.653-1.532 2.689-1.563 2.652 1.531zM183.173 144.053l-.003.855-2.688 1.565.002-.855 2.689-1.565zM180.484 145.617l-.002.856-2.653-1.533.003-.856 2.652 1.533zM183.173 144.053l-2.688 1.564-2.653-1.533 2.688-1.564 2.653 1.533zM200.164 153.871l-.003.857-2.689 1.564.003-.856 2.689-1.565z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M197.475 155.436l-.002.855-16.248-9.389.003-.856 16.247 9.39z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.163 153.872l-2.688 1.564-16.248-9.39 2.688-1.563 16.248 9.389zM203.559 155.835l-.002.856-2.689 1.563.003-.855 2.688-1.564z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M200.871 157.398l-.003.856-2.652-1.532.002-.856 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M203.559 155.835l-2.688 1.564-2.653-1.533 2.689-1.564 2.652 1.533zM206.955 157.797l-.003.855-2.688 1.564.002-.856 2.689-1.563zM204.266 159.36l-.002.855-2.653-1.532.002-.855 2.653 1.532z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M206.955 157.797l-2.688 1.563-2.653-1.532 2.688-1.564 2.653 1.533zM213.752 161.726l-.002.855-2.689 1.564.003-.856 2.688-1.563zM211.064 163.288l-.003.856-6.055-3.498.003-.856 6.055 3.498z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M213.752 161.725l-2.688 1.564-6.055-3.499 2.688-1.564 6.055 3.499zM171.739 130.923l-22.373-12.928 31.641-18.286 22.374 12.929-31.642 18.285z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M172.19 126.545l-20.203-11.675 28.571-16.512 20.203 11.675-28.571 16.512zM219.468 144.98l-14.595-8.435a1.41 1.41 0 010-2.442l16.619-9.604 18.732 10.825-16.708 9.656a4.044 4.044 0 01-4.048 0z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M195.708 58.65l-2.135 1.446 2.076 52.859 67.74 41.469 1.939-1.302a1.09 1.09 0 00.482-.905V99.762c0-.596-.317-1.147-.832-1.445l-68.66-39.687a.574.574 0 00-.61.02z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M262.558 99.64a2.077 2.077 0 011.037 1.798v52.442a.615.615 0 01-.923.534l-67.603-39.073a3.278 3.278 0 01-1.636-2.838v-52.06c0-.336.362-.545.653-.378"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M193.434 104.379V62.332l70.071 40.51.089 42.089-70.16-40.552z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M165.105 227.57s.828 8.584 2.217 11.55c1.389 2.965 4.281 2.51 6.511 2.097 2.231-.412 7.65-4.87 10.253-7.708 2.603-2.838 8.455-2.713 12.655-9.557 4.201-6.844-.609-9.318-2.072-9.354-1.463-.037-8.705 6.579-14.886 4.561l-14.678 8.411zM189.526 233.363s.825 8.584 2.214 11.549c1.39 2.966 4.281 2.51 6.512 2.097 2.231-.412 7.65-4.87 10.253-7.707 2.604-2.839 8.455-2.712 12.656-9.557 4.2-6.844-.609-9.319-2.073-9.355-1.463-.037-8.363 5.929-14.545 3.911l-15.017 9.062z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M187.117 179.017c-3.309-2.055-11.01-1.19-19.749.854-1.264-2.259-2.776-4.051-4.705-5.249-9.784-6.075-57.917 13.36-57.917 13.36s-6.283 26.706 2.87 32.539c3.52 2.243 11.117-.41 19.133-4.518.187-1.022.591-2.101.538-2.91 7.151 4.667 13.803 8.891 21.997 8.268 3.367-1.636 6.863-3.586 10.207-5.586 3.071 7.733 5.58 13.181 5.58 13.181 10.427 1.011 13.854-5.736 14.748-8.192a2.228 2.228 0 00-.037-1.611c-1.652-4.036-2.957-8.029-4.071-11.882l3.032-1.264c4.797 14.352 10.782 27.345 10.782 27.345 12.71 1.233 15.018-9.062 15.018-9.062-8.466-20.198-8.158-39.517-17.426-45.273z"
                    ></path>
                    <path
                        fill="#FF8A7D"
                        d="M158.67 142.099s3.271-7.611 4.378-8.409c1.108-.798 10.612-1.712 11.469-1.914.856-.201 2.71 1.562 2.71 1.562s4.897-.467 5.484-.36c.586.106.621 2.852-.678 2.941-1.298.09-3.734 1.02-3.734 1.02l-2.745 4.05s-4.834 3.654-5.7 3.711c-.865.056-6.85 1.61-6.85 1.61l-4.334-4.211z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M165.605 145.889s-1.207-7.124-6.224-6.794l-20.797 6.279a17.952 17.952 0 00-8.884-8.239l-21.169-9.039c-2.179-.93-4.546-1.362-6.908-1.176-4.323.34-10.51 2.2-12.489 9.749-3.145 12.007 12.65 58.918 12.65 58.918s13.76 14.923 23.775 13.515c10.015-1.407 23.94-10.843 23.476-17.93-.318-4.874-5.413-26.918-8.565-40.215l9.048 8.007 16.087-13.075zM158.782 257.439c-.409-.932-1.367-1.161-2.442-.442-.483.323-.919.795-1.28 1.338l-25.202-9.972 17.285-9.331c.056.001.112.002.169-.003.464.714 1.34.841 2.31.191 1.357-.907 2.371-2.963 2.264-4.593-.076-1.166-.709-1.784-1.558-1.716-.463-.715-1.339-.841-2.31-.192-1.32.882-2.307 2.851-2.262 4.458l-18.427 7.677-.661-10.114-5.802.381.574 8.782-9.755-5.423-3.201.948 12.14 8.804-26.432 7.762c-.242-.681-.75-1.034-1.39-.99-.465-.703-1.336-.824-2.3-.18-1.358.908-2.371 2.964-2.265 4.594.076 1.159.702 1.776 1.543 1.718.466.703 1.336.824 2.302.18.879-.589 1.607-1.66 1.987-2.782l27.163-6.65-1.222 21.746c-.539-.097-1.135.166-1.654.822-1.014 1.279-1.35 3.548-.75 5.065.349.882.938 1.303 1.568 1.261.495.407 1.226.4 2.023-.133 1.357-.907 2.371-2.963 2.265-4.593-.03-.451-.149-.813-.325-1.095l3.79-23.448 27.206 9.172c-.048.31-.076.618-.057.91.08 1.227.775 1.86 1.693 1.71.408.931 1.367 1.161 2.441.442 1.357-.907 2.371-2.963 2.264-4.593-.08-1.228-.774-1.861-1.692-1.711z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M125.408 197.82c-1.54-6.328-13.178-49.877-42.195-51.997-4.003-.288-7.746 2.12-9.088 5.914-2.744 7.818-6.136 23.748-1.25 48.249 4.378 22.006 15.09 34.207 28.934 39.11a20.454 20.454 0 006.902 1.192l-.018.042c8.23 1.058 17.337-1.364 18.296-1.577 27.489-5.99 42.704-18.494 43.823-27.983.506-4.314-14.587 15.161-31.693 10.822-12.037-4.838-13.711-23.772-13.711-23.772z"
                    ></path>
                    <path
                        stroke="#FEF3F3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                        d="M170.81 210.769c-29.08 19.154-38.914 7.233-41.761 1.38-.757-1.555-1.021-2.682-1.021-2.682s-8.209-31.408-17.378-45.543c-7.733-11.922-17.506-19.547-31.925-19.037"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M111.951 123.914l-3.422 4.178 21.169 9.038-1.926-7.467-15.821-5.749z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M225.608 179.608c-3.582 2.071-9.391 2.071-12.972 0-3.583-2.07-3.583-5.426-.001-7.496 3.582-2.07 9.39-2.07 12.973 0 3.582 2.07 3.582 5.426 0 7.496z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M228.294 175.86h-18.345v-15.352h18.345v15.352z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M225.608 164.257c-3.581 2.07-9.39 2.07-12.972 0-3.583-2.07-3.582-5.427 0-7.497 3.582-2.07 9.39-2.07 12.972 0 3.582 2.07 3.582 5.426 0 7.497z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M226.098 161.65c-.359.497-.924.965-1.703 1.365-2.854 1.464-7.472 1.464-10.326 0-.779-.4-1.344-.868-1.696-1.365-.944-1.325-.379-2.871 1.696-3.933 2.854-1.463 7.472-1.463 10.326 0 2.075 1.062 2.64 2.608 1.703 3.933z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M226.098 161.65c-.359.497-.924.965-1.703 1.365-2.854 1.464-7.472 1.464-10.326 0-.779-.4-1.344-.868-1.696-1.365.352-.497.917-.967 1.696-1.367 2.854-1.464 7.472-1.464 10.326 0 .779.4 1.344.87 1.703 1.367zM148.592 154.755c-.691 6.307-6.359 10.858-12.659 10.165-6.301-.692-10.848-6.365-10.156-12.672.691-6.305 6.359-10.857 12.66-10.165 6.3.693 10.847 6.366 10.155 12.672z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M171.231 168.066c-.65-.187-1.935-.919-2.452-1.39l-23.167-21.091-13.904 15.081s-1.828 1.707-.307 2.803L162.5 185.87a11.679 11.679 0 005.843 2.16c1.06-6.757 2.173-13.032 2.888-19.964z"
                    ></path>
                    <path
                        fill="#FF8A7D"
                        d="M191.878 159.421s2.982-7.729 4.058-8.568c1.077-.839 10.54-2.11 11.388-2.343.849-.232 2.768 1.461 2.768 1.461l1.951.955a1.914 1.914 0 01.416 3.163c-1.784 1.552-3.752 3.591-3.752 3.591s-4.692 3.832-5.555 3.921c-.864.089-6.786 1.866-6.786 1.866l-4.488-4.046z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M179.722 175.297c.572 5.54-3.452 10.497-8.987 11.069-5.535.572-10.486-3.455-11.058-8.995-.572-5.541 3.451-10.497 8.986-11.069 5.535-.573 10.487 3.455 11.059 8.995z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M200.001 162.557s-1.878-6.188-6.909-7.118c-1.045-.194-21.919 9.931-21.919 9.931-.781.447-1.642.99-2.509.932.76 6.927 1.716 13.19 2.102 20.019a11.676 11.676 0 005.938-2.561l23.297-21.203z"
                    ></path>
                    <path
                        fill="#FF8A7D"
                        d="M113.6 124.75l15.234 6.777 1.407-7.515c-5.395-1.156-10.705-2.513-15.807-4.69l-.834 5.428z"
                    ></path>
                    <path
                        stroke="#2500C4"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                        d="M137.065 103.588c.109-.021 2.694.092 4.061.153.434.02.741.429.643.853l-.475 2.043a1.82 1.82 0 01-1.424 1.374l-2.927.565"
                    ></path>
                    <path
                        fill="#FF8A7D"
                        d="M130.473 120.619c.858 4.439-2.042 8.734-6.477 9.592-4.436.858-8.726-2.045-9.584-6.484-.856-4.44 2.044-8.734 6.478-9.592a8.179 8.179 0 019.583 6.484z"
                    ></path>
                    <path
                        fill="#FF8A7D"
                        d="M113.719 112.527l.322 8.429c5.422 1.168 10.894 1.491 16.409 1.601l-.006-.973 3.798-.274a4.423 4.423 0 004.008-3.503l1.275-6.057a4.768 4.768 0 00-.204-2.662l-.415-1.101a4.34 4.34 0 01-.21-2.291l.253-1.402a4.799 4.799 0 00-.011-1.772l-1.977-10.24-23.242 20.245z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M122.123 87.46c-10.411 2.797-12.581 12.32-12.581 12.32s-2.316.154-2.807 3.185c-.396 2.449 3.937 12.035 5.616 15.625a2.397 2.397 0 002.153 1.382l4.773.044a7.744 7.744 0 006.212-3.029l5.148-6.706.291 1.503.961-.382a2.491 2.491 0 001.555-2.059l.869-8.386s6.582-1.862 6.977-10.453c0 0-8.756-5.842-19.167-3.044z"
                    ></path>
                    <path
                        fill="#FF8A7D"
                        d="M130.956 113.758v-4.474s-1.276-1.934-3.143-.708c-1.866 1.226.025 6.348 1.211 6.27 1.187-.08 1.932-1.088 1.932-1.088z"
                    ></path>
                    <path
                        fill="#FEF3F3"
                        d="M130.955 109.284l10.77-2.757.352-1.862-12.12 3.737.998.882z"
                    ></path>
                    <path
                        fill="#2500C4"
                        d="M113.84 122.527l-3.688 3.945 20.533 10.409-1.434-7.578-15.411-6.776z"
                    ></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_6_2803">
                    <path
                        fill="#fff"
                        d="M0 0H517V345H0z"
                        transform="translate(-79)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TypingMonkey;
