import { useMemo, useState, useCallback, useEffect, useRef } from "react";
import ProgressBar from "./ProgressBar";
import Toast, { TYPES } from "./Toast";
import data from "./data";
import Div100vh from 'react-div-100vh'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Complete from "./Complete";
import JSConfetti from 'js-confetti'

function App() {
  const [text, setText] = useState('')
  const [type, setType] = useState('')
  const jsConfetti = new JSConfetti()

  const { chars, count, all, codeSet } = useMemo(() => {
    const all = [...data.consonants, ...data.vowels, ...data.tones];
    const practiceSet = [...data.consonants, ...data.vowels, ...data.tones];

    return {
      codeSet: new Set(all.map(({ code }) => code)),
      all,
      chars: practiceSet,
      count: practiceSet.length,
    }
  }, [data])

  const [index, setIndex] = useState(0)
  const reset = () => {
    setIndex(0);
    setText('');
    setType('');
  }
  const onContinue = () => {
    reset();
  }

  const done = useMemo(() => {
    return index === chars.length;
  }, [index, chars])

  const timeoutID = useRef(undefined)
  const keyboardRef = useRef(null)

  function codeToKey(code) {
    if (!code) return '';
    return code.replace(/Digit|Key/, '');
  }

  const keyboardKey = useMemo(() => {
    if (!chars[index]) return '';
    return codeToKey(chars[index].code);
  }, [index])

  const handleUserKeyPress = useCallback(event => {

    if (index === chars.length) return;
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
    }
    const { code } = event;

    if (!codeSet.has(code)) return;

    document.querySelectorAll('.hg-button').forEach(el => {
      el.classList.remove("!bg-red-100", "!bg-green-200", "animate-pulse")
    })

    if (chars[index] && code === chars[index].code) {
      setIndex(index + 1);
      setType(TYPES.POSITIVE);
      setText('YOU GOT THIS!');

      timeoutID.current = setTimeout(() => {
        setType('');
        setText('');
      }, 1000);
    } else {
      const pressedButton = document.querySelector(`[data-skbtn="${codeToKey(code).toLowerCase()}"]`) || document.querySelector(`[data-skbtn*="${codeToKey(code).toLowerCase()}"]`);
      const targetButton =  document.querySelector(`[data-skbtn="${codeToKey(chars[index].code).toLowerCase()}"]`) || document.querySelector(`[data-skbtn*="${codeToKey(chars[index].code).toLowerCase()}"]`);

      if (pressedButton) {
        pressedButton.classList.add("!bg-red-100")
      }
      if (targetButton) {
        targetButton.classList.add("!bg-green-200", "animate-pulse");
      }
      setType(TYPES.NEGATIVE);
      setText('OOPS. TRY AGAIN');
    }
  }, [index]);

  const onKeyPress = useCallback(button => {
    if (index === chars.length) return;

    const normalized = button.replace(/{|}/g, '')
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
    }
    const key = codeToKey(chars[index].code).toLowerCase();
    const pressedButton = document.querySelector(`[data-skbtn="${normalized}"]`) || document.querySelector(`[data-skbtn*="${normalized}"]`);
    if (!pressedButton) return;

    document.querySelectorAll('.hg-button').forEach(el => {
      el.classList.remove("!bg-red-100", "!bg-green-200", "animate-pulse")
    })

    if (key === normalized) {
      setIndex(index + 1);
      setType(TYPES.POSITIVE);
      setText('YOU GOT THIS!');

      timeoutID.current = setTimeout(() => {
        setType('');
        setText('');
      }, 1000);
    } else {
      const targetButton = document.querySelector(`[data-skbtn="${key}"]`) || document.querySelector(`[data-skbtn*="${key}"]`);

      if (pressedButton) {
        pressedButton.classList.add("!bg-red-100")
      }
      if (targetButton) {
        targetButton.classList.add("!bg-green-200", "animate-pulse");
      }
      setType(TYPES.NEGATIVE);
      setText('OOPS. TRY AGAIN');
    }
  }, [index]);
  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (done){
      jsConfetti.addConfetti({
        emojis: ['🎉'],
     })
    }
  }, [done]);
  const buttonClasses = useMemo(() => {
    const classes = all.map(({ b, code }) => {
      const key = codeToKey(code).toLowerCase();

      return {
        class: `zy-${key}`,
        buttons: `${key.length === 1 ? key : `{${key}}`}`
      };
    })
    classes.push({
      class: 'zy',
      buttons: all.map(({ code }) => {
        const key = codeToKey(code).toLowerCase();
        return key.length === 1 ? key : `{${key}}`;
      }).join(' ')
    })
    return classes;
  }, [all])

  return (
    <Div100vh className={`relative ${done ? '' : 'bg-slate-100'} w-screen flex flex-col items-center`}>
      <div className="text-3xl text-center text-base font-bold py-4 bg-white w-full">
        <span>
          Practice
        </span>
      </div>
      <ProgressBar progress={Math.floor(index / count * 100)} />
      {done ? <Complete onClick={onContinue} /> :
        <>
          <main className="w-full height-[360px] text-9xl h-80 flex-shrink-0 flex justify-center items-center bg-white">
            <span>
              {chars[index] ? chars[index].b : ''}
            </span>
          </main>
          {text &&
            <div className="relative w-full flex flex-row justify-center">
              <div className="absolute flex-grow mx-auto w-full max-w-xs -translate-y-1/2">
                <Toast text={text} type={type} />
              </div>
            </div>
          }
          {<div className="absolute bottom-0 w-full px-1 md:max-w-3xl"><Keyboard
            keyboardRef={r => (keyboardRef.current = r)}
            layoutName={'default'}
            onKeyPress={onKeyPress}
            physicalKeyboardHighlight={true}
            syncInstanceInputs={true}
            mergeDisplay={true}
            layout={{
              default: [
                "1 2 3 4 5 6 7 8 9 0",
                "q w e r t y u i o p",
                "a s d f g h j k l {semicolon}",
                "z x c v b n m {comma} {period} {slash}",
                "{numbers} {space} {minus} {ent}"
              ],
            }}
            display={{
              "{numbers}": "123",
              "{ent}": "return",
              "{escape}": "esc ⎋",
              "{tab}": "tab ⇥",
              "{backspace}": "⌫",
              "{capslock}": "caps lock ⇪",
              "{shift}": "⇧",
              "{controlleft}": "ctrl ⌃",
              "{controlright}": "ctrl ⌃",
              "{altleft}": "alt ⌥",
              "{altright}": "alt ⌥",
              "{metaleft}": "cmd ⌘",
              "{metaright}": "cmd ⌘",
              "{abc}": "ABC",
              "{semicolon}": ";",
              "{comma}": ",",
              "{period}": ".",
              "{slash}": "/",
              "{minus}": "-"
            }}
            buttonTheme={buttonClasses}
          /></div>
          }
        </>}
    </Div100vh>
  );
}

export default App;
