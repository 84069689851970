export default {
    consonants: [
        {
            b: "ㄅ",
            p: "b",
            code: "Digit1",
        },
        {
            b: "ㄆ",
            p: "p",
            code: "KeyQ",
        },
        {
            b: "ㄇ",
            p: "m",
            code: "KeyA",
        },
        {
            b: "ㄈ",
            p: "f",
            code: "KeyZ",
        },
        {
            b: "ㄉ",
            p: "d",
            code: "Digit2",
        },
        {
            b: "ㄊ",
            p: "t",
            code: "KeyW",
        },
        {
            b: "ㄋ",
            p: "n",
            code: "KeyS",
        },
        {
            b: "ㄌ",
            p: "l",
            code: "KeyX",
        },
        {
            b: "ㄍ",
            p: "g",
            code: "KeyE",
        },
        {
            b: "ㄎ",
            p: "k",
            code: "KeyD",
        },
        {
            b: "ㄏ",
            p: "h",
            code: "KeyC",
        },
        {
            b: "ㄐ",
            p: "j",
            code: "KeyR",
        },
        {
            b: "ㄑ",
            p: "q",
            code: "KeyF",
        },
        {
            b: "ㄒ",
            p: "x",
            code: "KeyV",
        },
        {
            b: "ㄓ",
            p: "zhi",
            code: "Digit5",
        },
        {
            b: "ㄔ",
            p: "chi",
            code: "KeyT",
        },
        {
            b: "ㄕ",
            p: "shi",
            code: "KeyG",
        },
        {
            b: "ㄖ",
            p: "ri",
            code: "KeyB",
        },
        {
            b: "ㄗ",
            p: "zi",
            code: "KeyY",
        },
        {
            b: "ㄘ",
            p: "ci",
            code: "KeyH",
        },
        {
            b: "ㄙ",
            p: "si",
            code: "KeyN",
        },
    ],
    // wikipedia calls them medial and rhymes
    vowels: [
        {
            b: "ㄚ",
            p: "a",
            code: "Digit8",
        },
        {
            b: "ㄛ",
            p: "o",
            code: "KeyI",
        },
        {
            b: "ㄜ",
            p: "e",
            code: "KeyK",
        },
        {
            b: "ㄝ",
            p: "-ie",
            code: "Comma",
        },
        {
            b: "ㄞ",
            p: "ai",
            code: "Digit9",
        },
        {
            b: "ㄟ",
            p: "ei",
            code: "KeyO",
        },
        {
            b: "ㄠ",
            p: "ao",
            code: "KeyL",
        },
        {
            b: "ㄡ",
            p: "ou",
            code: "Period",
        },
        {
            b: "ㄢ",
            p: "an",
            code: "Digit0",
        },
        {
            b: "ㄣ",
            p: "en",
            code: "KeyP",
        },
        {
            b: "ㄤ",
            p: "ang",
            code: "Semicolon",
        },
        {
            b: "ㄥ",
            p: "eng",
            code: "Slash",
        },
        {
            b: "ㄦ",
            p: "er",
            code: "Minus",
        },
        {
            b: "ㄧ",
            p: "yi",
            code: "KeyU",
        },
        {
            b: "ㄨ",
            p: "wu",
            code: "KeyJ",
        },
        {
            b: "ㄩ",
            p: "ǚ",
            code: "KeyM",
        },

    ],
    tones: [
        {
            b: "ˇ",
            p: "",
            code: "Digit3",
        },
        {
            b: "ˋ",
            p: "",
            code: "Digit4"
        },
        {
            b: "ˊ",
            p: "",
            code: "Digit6"
        },
        {
            b: "˙",
            p: "",
            code: "Digit7"
        },
    ]
}