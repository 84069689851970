import TypingMonkey from "./TypingMonkey";

function Complete({ onClick }) {
    return (
        <div className="w-full flex flex-col px-4 flex-grow">
            <span className="text-4xl font-bold text-left pt-4">
                Great job, you’re
                making progress.
            </span>
            <div class="mb-auto">
                <TypingMonkey />
            </div>
            <button
                onClick={onClick}
                class="mb-10 rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
            >
                Practice again
            </button>
        </div>
    );
}
export default Complete